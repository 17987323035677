import { InvestorReportDTO } from '@types';
import { useMemo, useState } from 'react';
import { TableSort } from '@/types';
import { getNewSortStateTable } from '@/utils/tableSort';
import { GenericTable } from '@/components/Table/Table';
import {
  columnsForClientsView,
  mapApiInvestorReportsDataToTableData,
  sortForInvestorReportingTableData,
} from '@/pages/InvestorReporting/components/Clients/investorReportingClient.util';
import {
  findTemplateForReport,
  generateURLForReportDetailsV1,
  InvestorListProps,
  InvestorReportingTableData,
} from '@/pages/InvestorReporting/utils/investorReports';
import { useNavigate } from 'react-router-dom';

export function InvestorReportsListForClients({
  investorReports,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  searchText,
  investorReportTemplates,
}: InvestorListProps) {
  const navigate = useNavigate();
  const [sort, setSort] = useState<TableSort<InvestorReportingTableData>>({
    field: 'action' as keyof InvestorReportingTableData,
    asc: true,
  });

  const getSortValue = (sortField: string, report: InvestorReportDTO) => {
    switch (sortField) {
      case 'name':
        return report?.firm
          ? report?.firm?.firm_name || ''
          : `${report?.user?.first_name as string} ${
              report?.user?.last_name as string
            }` || '';
      case 'created_at':
        return report?.created_at ? new Date(report?.created_at).getTime() : 0;
      case 'frequency':
        return report?.frequency;
      case 'next_report': {
        const template = findTemplateForReport(investorReportTemplates, report);
        return template?.starting_date || 42;
      }
      default:
        return report[sortField as keyof InvestorReportDTO];
    }
  };

  const sortReports = (): InvestorReportDTO[] =>
    investorReports
      .sort((c1, c2) => {
        const obj1 = getSortValue(sort.field, c1);
        const obj2 = getSortValue(sort.field, c2);
        if (obj1?.toString() && obj2?.toString() && obj1 > obj2)
          return sort.asc ? 1 : -1;
        return sort.asc ? -1 : 1;
      })
      .filter((report) => {
        return (
          report.firm?.firm_name
            .toLowerCase()
            .match(searchText.toLowerCase()) ||
          `${report.user?.first_name as string} ${
            report.user?.last_name as string
          }`
            .toLowerCase()
            .match(searchText.toLowerCase()) ||
          report.frequency
            .toString()
            .toLowerCase()
            .match(searchText.toLowerCase())
        );
      });

  const sortBy = (fieldName: keyof InvestorReportingTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const sortedClientRecords = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => sortReports(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, selectedPage, searchText, investorReports]
  );

  const reportData = mapApiInvestorReportsDataToTableData(
    sortedClientRecords,
    investorReportTemplates
  );

  return (
    <div className='mt-4'>
      <GenericTable
        className='w-full mb-2'
        tableName='Investor Clients'
        data={reportData as InvestorReportingTableData[]}
        columns={columnsForClientsView(true)}
        sortBy={sortBy}
        sort={sort}
        sortFunction={sortForInvestorReportingTableData(sort)}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={onPageSelect}
        onSelectRowsChange={onSelectRowsChange}
        onRowClick={(row) => {
          const url = generateURLForReportDetailsV1(
            '',
            row.client_id,
            row.firm_id,
            row.user_id,
            row.id
          );
          navigate(url);
        }}
      />
    </div>
  );
}
