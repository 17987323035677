import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GenericTable } from '@/components/Table/Table';
import { TableSort } from '@/types';
import { getNewSortStateTable } from '@/utils';
import {
  generateReportsTableData,
  generateURLForReportDetailsV1,
  InvestorListProps,
  InvestorReportingGroupedTableData,
  ReportsGroupedData,
} from '@/pages/InvestorReporting/utils/investorReports';
import { post } from '@/utils/queries';
import { InvestorReportDTO } from '@types';
import { alertErrorMessage, alertMessage } from '@/utils/alerts';
import SendReportNudgeModal from '@/pages/InvestorReporting/components/Investors/SendReportNudgeModal';
import {
  columnsForInvestorGroupedView,
  mapApiInvestorReportsGroupedToTableData,
  sortForInvestorGroupedView,
} from './investorReporting.util';

interface InvestorClientListProps extends InvestorListProps {
  updateData: () => Promise<void>;
}

function InvestorClientList({
  investorReports,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  searchText,
  investorReportTemplates,
  updateData,
}: InvestorClientListProps) {
  const navigate = useNavigate();
  const [sort, setSort] = useState<
    TableSort<InvestorReportingGroupedTableData>
  >({
    field: 'action' as keyof InvestorReportingGroupedTableData,
    asc: true,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [overdueReports, setOverdueReports] = useState<
    InvestorReportDTO[] | null
  >(null);
  const [reportsData, setReportsData] = useState<ReportsGroupedData[]>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';

  const openNudgeModal = (
    rows: InvestorReportDTO[],
    e: React.FormEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    setOverdueReports(rows);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setOverdueReports(null);
  };

  const sendReminder = async (reportsToNudge: InvestorReportDTO[]) => {
    if (reportsToNudge) {
      try {
        await Promise.all(
          reportsToNudge.map(async (report) => {
            await post(`investorReports/nudge/client`, {
              investorReportId: report.id,
            });
          })
        );
        alertMessage('top-right', 'Reminder sent');
      } catch (error) {
        alertErrorMessage('Failed to send reminder');
      }
      closeModal();
      await updateData();
    }
  };

  const sortBy = (fieldName: keyof InvestorReportingGroupedTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };
  useEffect(() => {
    const data = generateReportsTableData(
      investorReports,
      investorReportTemplates
    );
    setReportsData(data);
  }, [investorReports, investorReportTemplates]);

  const sortedReports = useMemo(() => {
    return (reportsData || []).filter((report) =>
      report.company_name?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [reportsData, searchText]);

  const reportData = mapApiInvestorReportsGroupedToTableData(sortedReports);

  return (
    <div className='mt-4'>
      <GenericTable
        className='w-full mb-2'
        tableName='Investor Clients'
        data={reportData}
        columns={columnsForInvestorGroupedView(openNudgeModal)}
        sortBy={sortBy}
        sort={sort}
        sortFunction={sortForInvestorGroupedView(sort)}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={onPageSelect}
        onSelectRowsChange={onSelectRowsChange}
        onRowClick={(row) => {
          if (!overdueReports) {
            const reportId =
              row.investorReports.length > 0
                ? (row.investorReports[0].id as number)
                : undefined;

            const url = generateURLForReportDetailsV1(
              clientNameFromQuery,
              row.client_id,
              row.firm_id,
              row.user_id,
              reportId
            );
            navigate(url);
          }
        }}
      />
      {overdueReports ? (
        <SendReportNudgeModal
          showModalStatus={isModalOpen}
          onClose={closeModal}
          overdueReports={overdueReports || []}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={async (report) => sendReminder(report)}
          companyName={overdueReports?.[0].client?.client_name || ''}
        />
      ) : null}
    </div>
  );
}

export default InvestorClientList;
