import { useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GenericTable } from '@/components/Table/Table';
import { TableSort } from '@/types';
import { getNewSortStateTable } from '@/utils';
import {
  generateURLForReportDetailsV1,
  InvestorListProps,
  InvestorReportingTableData,
} from '@/pages/InvestorReporting/utils/investorReports';
import {
  columnsForPastUpdates,
  mapApiInvestorReportsDataToTableData,
} from '../Investors/investorReporting.util';

function InvestorReportingPastUpdates({
  investorReports,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  searchText,
  investorReportTemplates,
}: InvestorListProps) {
  const navigate = useNavigate();
  const [sort, setSort] = useState<TableSort<InvestorReportingTableData>>({
    field: 'action' as keyof InvestorReportingTableData,
    asc: true,
  });
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';

  const sortBy = (fieldName: keyof InvestorReportingTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const sortedReports = useMemo(() => {
    return investorReports.filter((report) =>
      report.client?.client_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
    );
  }, [investorReports, searchText]);

  const reportData = mapApiInvestorReportsDataToTableData(
    sortedReports,
    investorReportTemplates
  );
  return (
    <div className='mt-4'>
      <GenericTable
        className='w-full mb-2'
        tableName='Investor Clients'
        data={reportData as InvestorReportingTableData[]}
        columns={columnsForPastUpdates}
        sortBy={sortBy}
        sort={sort}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={onPageSelect}
        onSelectRowsChange={onSelectRowsChange}
        onRowClick={(row) => {
          const reportId = row.id ? row.id : undefined;
          const url = generateURLForReportDetailsV1(
            clientNameFromQuery,
            row.client_id,
            row.firm_id,
            row.user_id,
            reportId
          );
          navigate(url);
        }}
      />
    </div>
  );
}

export default InvestorReportingPastUpdates;
